import 'core-js/stable' ;
import Vue from "vue" ;
import CreateApp from "./app" ;
import { Store } from "vuex" ;
import { AxiosError, isCancel } from 'axios';
import {
    AnalysisBaiduPinliteId,
    AnalysisInitBaidu,
    BigBigWork,
    CreateCrossDomain,
    CreateProxy,
    GuideManager,
    waitLogin,
    keepCacheInit,
    CrossDomainData, crossDomainMessage,
    handleProxyError, saveData, LocalStorageWrite, LocalStorageRead
} from "bbw-common" ;
import { GuideConfig } from "@scripts/GuideConfig" ;
import { AnalysisInit } from "@scripts/Analysis" ;
import Router from "vue-router" ;
import Cookies from "js-cookie" ;
import "bbw-components/styles/index.scss" ;
import "../assets/styles/element-variables.scss" ;
import "../assets/styles/common.scss" ;
const allSettled = require("promise.allsettled");

window.Promise = Promise ;
window.routerHistory = [] ;

keepCacheInit () ;
let __VUE_STORE__ = null ;
/**
 * 修改 vue-router 的push方法
 */
const originalPush = Router.prototype.push ;
// @ts-ignore
Router.prototype.push = function push ( location, onResolve, onReject ) {
    if ( onResolve || onReject ) {
        return originalPush.call ( this, location, onResolve, onReject ) ;
    }
    return originalPush.call ( this, location ).catch ( err => err ) ;
} ;

/** 错误捕获 **/
const errorHandle = function ( err ) {
    if ( isCancel (err) ) return;
    console.error ( `errorHandler`, err ) ;
    if ( !err ) return ;
    const errInfo = {
        stack: err.stack,
        msg: err.message,
        href: window.location.href,
        time: new Date ().getTime (),
        userId: __VUE_STORE__?.state?.UserInfo?.id
    } ;
    // 网络错误记录
    if ( err.isAxiosError ) {
        errInfo.net = {
            url: err.config?.url,
            status: err?.response?.status,
            json: err.toJSON ()
        }
    }
    const errors = LocalStorageRead ( `errorTrace`, ``, false ) || [] ;
    errors.push ( errInfo ) ;
    // 本地错误记录
    LocalStorageWrite (
        ``, `errorTrace`,
        errors,
        null,
        false,
        5
    ) ;
};
if ( window._BBW_ERRORS && window._BBW_ERRORS.length ) {
    for ( let i = 0; i < window._BBW_ERRORS.length; i++ ) {
        errorHandle ( window._BBW_ERRORS[i] );
    }
}
Vue.config.errorHandler = errorHandle ;
window.errorHandle = errorHandle ;
// Vue.config.errorHandler = errorHander ;
/** asyncData请求初始化 */
Vue.mixin ( {
    beforeRouteUpdate ( to, from, next ) {
        // eslint-disable-next-line no-undef
        _hmt.push ( [`_trackPageview`, to.path] ) ;
        window.routerHistory.push ( to.path ) ;
        console.log ( `beforeRouteUpdate` ) ;
        const { asyncData } = this.$options ;
        if ( asyncData ) {
            asyncData ( {
                store: this.$store,
                route: to
            } ).then ( next ).catch ( next ) ;
        } else {
            next () ;
        }
    },
    beforeRouteEnter ( to, from, next ) {
        next ( vm => {
            console.log ( `beforeRouteEnter` ) ;
            window.routerHistory.push ( to.path ) ;
            // eslint-disable-next-line no-undef
            _hmt.push ( [`_trackPageview`, to.path] ) ;
        } ) ;
    }
} ) ;
const handleCrossDomain = () => {
    if ( window.crossDomainReady ) {
        console.log ( `跨域iframe加载成功` ) ;
        window.setTimeout(async () => {
            const [err, isIncognito] = await crossDomainMessage<string> ( `GetCookie`, `crossdomain`, `ReturnGetCookie_crossdomain`, 300 ) ;
            console.log('isIncognitoisIncognitoisIncognitoisIncognito', isIncognito)
            CrossDomainData.ready = true ;
            if ( !err && isIncognito ) {
                CrossDomainData.isIncognito = false ;
            } else {
                CrossDomainData.isIncognito = true ;
            }
            BigBigWork.emit ( `CROSS_DOMAIN_READY` ) ;
        }, 200)
    }else{
        console.log ( `跨域iframe加载失败` ) ;
        CrossDomainData.ready = true ;
        CrossDomainData.isIncognito = false ;
        BigBigWork.emit ( `CROSS_DOMAIN_FAIL` ) ;
    }
}
/** 初始化客户端前调用 */
const beforeInitClient = async function ( app, store:Store<any>, router ) {
    /** 初始化axios请求拦截 */
    try {
        if ( __DEV__ && !console.dir ) {
            console.dir = ( msg ) => {
                console.log ( JSON.stringify ( msg ) ) ;
            } ;
        }
        store.commit ( `setClientWidth`, document.body.clientWidth ) ;
        store.commit ( `System/initUAInfo` ) ;
        store.commit ( `System/checkImageSetPrefix` ) ;
        if ( window.crossDomainReady !== undefined ) {
            handleCrossDomain();
        }else{
            const iframe = document.getElementById('crossDomain');
            iframe.addEventListener('load', ( ) => {
                window.crossDomainReady = true;
                handleCrossDomain();
            })
            iframe.addEventListener('error', ( ) => {
                window.crossDomainReady = false;
                handleCrossDomain();
            })
        }
        // await CreateCrossDomain ().catch ( e => { throw e ; } ) ;
        window.addEventListener ( `resize`, () => {
            store.commit ( `setClientWidth`, document.body.clientWidth ) ;
        } ) ;
        // if ( __DEV__ ) {
        //     const Vconsole = ( await import ( `vconsole` ) ).default ;
        //     const vc = new Vconsole () ;
        // }
    } catch ( e ) {
        throw e ;
    }
} ;

/** 创建客户端 */
const CreateEntryClient = async () => {
    try {
        const { app, router, store } = CreateApp () ;
        if ( window.__INITIAL_STATE__ ) {
            store.replaceState ( window.__INITIAL_STATE__ ) ;
        }
        __VUE_STORE__ = store ;
        router.onReady ( async () => {
            // Add router hook for handling asyncData.
            // Doing it after initial route is resolved so that we don't double-fetch
            // the data that we already have. Using router.beforeResolve() so that all
            // async components are resolved.
            router.beforeResolve ( ( to, from, next ) => {
                const matched = router.getMatchedComponents ( to ) ;
                const prevMatched = router.getMatchedComponents ( from ) ;
                let diffed = false ;
                const activated = matched.filter ( ( c, i ) => {
                    return diffed || ( diffed = ( prevMatched[i] !== c ) ) ;
                } ) ;
                const asyncDataHooks = activated.map ( c => c.asyncData ).filter ( _ => _ ) ;
                if ( !asyncDataHooks.length ) {
                    return next () ;
                }

                // bar.start()
                Promise.all ( asyncDataHooks.map ( hook => hook ( { store, route: to } ) ) )
                    .then ( () => {
                        // bar.finish()
                        next () ;
                    } )
                    .catch ( next ) ;
            } ) ;
            handleProxyError ( store ) // 处理图片加载错误
            await allSettled ([
                beforeInitClient ( app, store, router ), // 初始化客户端
                CreateProxy ( store ) // 获取代理服务器
            ])
            // 挂载客户端DOM
            app.$mount ( `#app` ) ;
            // 环境设为客户端
            store.commit ( `setIsClient` ) ;
            GuideManager.init ( GuideConfig, store ) ;
            await waitLogin ( store ) ;
            await store.dispatch ( `AiEntry/FETCH_AI_ENTRY` ) ;
            // window.setTimeout ( () => {
            //     AnalysisInitBaidu ( AnalysisBaiduPinliteId ) ;
            //     // 初始化统计系统
            //     AnalysisInit () ;
            // }, 3000 ) ;
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;
// service worker
// if ('https:' === location.protocol && navigator.serviceWorker) {
//   navigator.serviceWorker.register('/service-worker.js')
// }

CreateEntryClient () ;

export default CreateEntryClient ;

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept ( ) ;
}
