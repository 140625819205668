<template>
  <div
      :class="$style.SearchWrapper"
      :style="headerStyle"
      ref="wrapper"
      @click.capture="handleClick" @mousedown.capture=handleClick
  >
    <div :class="style">
      <slot></slot>
      <div :class="$style.SearchArea" ref="wrapper" id="searchInput">
        <div class="center">
          <a
              @click.prevent.stop="toHome"
              href="/"
              :class="[$style.pinLiteLogo,isSemH?$style.da_logo:'']"
          ></a>
          <a
              @click.prevent.stop="toHome"
              href="/"
              :class="[$style.toHome, $style.nav]"
          >主页</a
          >
          <!-- 搜索区域开始 -->
          <div :class="$style.searchBlock">
            <div :class="[$style.SearchInputWrapper,searchActive && $style.active]" v-if="!$store.server">
              <!--未输入时的Placeholder-->
              <div :class="$style.searchPlaceHolder">
                <svg
                    :class="$style.icon"
                    height="20"
                    width="20"
                    viewBox="0 0 24 24"
                    role="img"
                >
                  <path
                      d="M10 16c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6m13.12 2.88l-4.26-4.26A9.842 9.842 0 0 0 20 10c0-5.52-4.48-10-10-10S0 4.48 0 10s4.48 10 10 10c1.67 0 3.24-.41 4.62-1.14l4.26 4.26a3 3 0 0 0 4.24 0 3 3 0 0 0 0-4.24"
                  ></path>
                </svg>
                <!--                <span v-if="!kw && !searchActive" :class="[$style.placeholderCss,'font-medium']">输入搜索词</span>-->
                <!--                <span v-if="!kw && !searchActive" :class="[$style.placeholderCss,'font-medium']">开始搜索 <span :class="[$style.Ptitle,'font-bold']">Pinterest<span class="font-bold">极速版</span></span>，发现全球灵感</span>-->
              </div>
              <!-- 输入框 @mousedown.stop="enterInputMode"-->
              <input
                  :placeholder="inputPlaceholder"
                  :class="[$style.kwInput,'font-medium']"
                  v-model="kw"
                  @mousedown.stop="enterInputMode"
                  @focus="focusInputMode"
                  @input="enterInputMode"
                  @keydown.enter="onKeydown"
                  ref="kwInput"
                  maxlength="40"
              />
              <!--              <input
                                autofocus
                                placeholder="搜索"
                                :class="$style.kwInput"
                                @mousedown.stop="enterInputMode"
                                :style="{ opacity: !kw && !searchActive ? 0 : 1 }"
                                v-model="kw"
                                @input="onInputChange"
                                @keydown.enter="onKeydown"
                                ref="kwInput"
                            />-->
              <a :class="$style.reset" @click="reset" v-if="keyword.length">
                <svg
                    class="gUZ pBj U9O kVc"
                    height="16"
                    width="16"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    aria-label=""
                    role="img"
                >
                  <path
                      d="M15.18 12l7.16-7.16c.88-.88.88-2.3 0-3.18-.88-.88-2.3-.88-3.18 0L12 8.82 4.84 1.66c-.88-.88-2.3-.88-3.18 0-.88.88-.88 2.3 0 3.18L8.82 12l-7.16 7.16c-.88.88-.88 2.3 0 3.18.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66L12 15.18l7.16 7.16c.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66.88-.88.88-2.3 0-3.18L15.18 12z"
                  ></path>
                </svg>
              </a>
              <!-- 搜索词占位 -->
              <div :class="$style.keyword">{{ kw }}</div>
              <!-- 搜索词翻译 -->
              <span
                  v-show="!searchActive"
                  v-if="translatedWords.length"
                  :class="$style.translateLabel"
              >用英文搜索：</span
              >
              <router-link
                  v-show="!searchActive"
                  @click.stop
                  :class="$style.translateWord"
                  v-for="(item, index) in translatedWords"
                  :key="index"
                  :to="{ path: '/tupian', query: { kw: item } }"
              >
                <span @mousedown="handleTranslateClick(item)">{{ item }}</span></router-link
              >
            </div>
            <div :class="[$style.dropDown]" v-show="searchActive" @mousedown.stop="handleWrapper">
              <!-- 搜索历史 -->
              <SearchHistory
                  :words="SearchHistory"
                  v-if="!kw.length"
                  @changeHistory='handleHistoryChange'
                  @isHistory="isHistory"
              ></SearchHistory>
              <!-- 热门搜索 -->
              <SearchHotWords
                  :isHistoryVal="isHistoryVal"
                  :keyword="kw"
                  v-if="!kw.length"
                  @hotWordsList="hotWordsList"
              ></SearchHotWords>
              <!-- 推荐词 -->
              <SearchRecommended
                  v-if="kw.length && (recommended.length || recommendBoardsList.length)"
                  :keyword="kw"
                  :search-word="recommendSearchWord"
                  :recommendBoardsList="recommendBoardsList"
                  :recommended-words="recommended"
                  @disableSearch=disableSearch
                  @changeKeyword=handleChangeKeyword
                  @onKeywordChange=onKeywordChange
              />
            </div>
          </div>
          <!-- 搜索区域结束 -->
          <!-- 已登录右侧开始 -->

            <div id="p-button" :style="{'margin-left':'32px'}" :class="$style.btnBox" v-if="isShowButton('pl_search_p')">
                <ButtonMask :icon="siteListOps.pinSrc"
                            :hover-icon="siteListOps.pinSrcHover"
                            :mask="false" @onBtnCli="pinCli"></ButtonMask>
            </div>

          <div v-if="loginState === undefined ? $store.state.token : loginState "
               :class="[$style.advice, 'dropdownOuter']">
            <span :class="$style.text">
              反馈建议
            </span>
            <div :class="['dropdownWrapper', $style.qrcodeWrapper]">
              <div :class="[$style.qrcode, 'dropdownMain']">
                <img :src="ServiceQR"/>
                <span>扫码添加客服</span>
                <p>有任何意见请</p>
                <p>随时联系我们</p>
              </div>
            </div>
          </div>
          <!-- 已登录右侧结束 -->
          <a
              v-if="!(loginState === undefined ? $store.state.token : loginState )"
              @click.prevent="goPinliteRegister(loginRedirect || $store.state.route.fullPath)"
              :href="registerUrl"
              :class="[$style.btn, $style.register,'font-bold']"
          >注册</a
          >
          <a
              v-if="!(loginState === undefined ? $store.state.token : loginState )"
              @click.prevent="goPinliteLogin(loginRedirect || $store.state.route.fullPath)"
              :href="loginUrl"
              :class="[$style.btn, $style.login,'font-bold']"
          >登录</a>
        </div>
      </div>
    </div>
    <div :class="$style.mask" v-if="searchActive"></div>
    <!--    <div :class="$style.mask"></div>-->
  </div>
</template>
<script lang="ts">
import axios from "axios";
import {ServiceQR} from "@scripts/util";
import SearchRecommended from "../SearchRecommended/SearchRecomeneded";
import SearchHistory from "../SearchHistory/SearchHistory";
import SearchHotWords from "../SearchHotWords/SearchHotWords";
import {trim} from "lodash-es"
import {
    ServerBoardServer,
    getToken,
    goPinliteRegister,
    goPinliteLogin,
    initStateReady,
    ServerTranslateNode,
    ServerPinLite,
    PagePinliteTupian,
    getPinliteRegister,
    getPinliteLogin, BigBigWork,
    ServerPinliteService, getTokenCrossDomain, ServerRecommendWord, goBoards, Cache, CacheItems,
    statsDzToAi, statsDzActionClick
} from "bbw-common";
import Cookies from "js-cookie";
import {Route} from "vue-router";
// import {CacheItems} from "bbw-common/src";
import ButtonMask from "../../components/ButtonMask/ButtonMask.vue"
import {Scripts} from "bbw-components";
/** 搜索框 */
export default {
  /** 导航栏高度 */
  props: {
    headerStyle:{
      type: Object,
      required:false,
      default:()=>{
        return {
          height: "120px"
        }
      }
    },
      keywordList:{
          type:Array,
          default:()=>{
            return []
          }
      },
    /** 搜索词 */
    keyword: {
      type: String,
      required: false,
      default: ""
    },
    /** 搜索词翻译 */
    translatedWords: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    /** 未登录页面，点击登录注册后的跳转地址 */
    loginRedirect: {
      type: String,
      required: false
    },
    /** 登录状态 */
    loginState: {
      type: Boolean,
      required: false,
      default: undefined
    },
    /** 统计来源 */
    resource: {
      type: String,
      required: false,
      default: "其他"
    },
    /** 是否打开登录弹窗 */
    showRegisterDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    /** 是否为sem_h */
    isSemH: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  name: "SearchInput",
  components: {SearchRecommended, SearchHistory, SearchHotWords,ButtonMask},
  // mounted() {
  //     document.addEventListener("click",this.disableSearch);
  //     console.log('SearchInput mounted')
  //     this.onMount()
  // },
  mounted() {
    this.registerUrl = this.getPinliteRegister(this.loginRedirect || this.$store.state.route.fullPath)
    this.loginUrl = getPinliteLogin(this.loginRedirect || this.$store.state.route.fullPath);
    // this.$nextTick(() => {
    //   this.$refs.kwInput.focus();
    // })
  },
  async activated() {
    try {
      BigBigWork.on("onChangeRightSidePos", this.onScroll)
      document.addEventListener("mousedown", this.disableSearch);
      this.kw = this.keyword;
      window.addEventListener("scroll", this.onScroll);
      console.log("SearchInput activated");
      this.onScroll();
      // this.$nextTick(() => {
      //   this.$refs.kwInput.focus();
      // })
    } catch (e) {
      throw e;
    }
  },
  // destroyed() {
  //     document.removeEventListener("click",this.disableSearch);
  //     window.removeEventListener('scroll',this.onScroll);
  //     console.log('SearchInput destroyed')
  // },
  deactivated() {
    document.removeEventListener("mousedown", this.disableSearch);
    window.removeEventListener("scroll", this.onScroll);
    console.log("SearchInput deactivated");
    BigBigWork.removeListener("onChangeRightSidePos", this.onScroll);
  },
  data() {
    return {
      kw: this.keyword,
      /** 推荐词 */
      recommended: [],
      /** board 图片集 */
      recommendBoardsList: [],
      /** 推荐词接口cancelToken, 用于快速输入时撤销推荐词搜索 */
      recommendedCancel: null,
      /** 推荐词搜索使用的搜索词，用于推荐词下拉加粗文字 */
      recommendSearchWord: "",
      /** 是否选中搜索框 **/
      searchActive: false,
      /** 搜索历史 */
      SearchHistory: [],
      /** 避免重复获取搜索用户,调用该函数保证只获取一次userName */
      getUserName: initStateReady<string>(this.$store, {
        state: "PinUser/commonUser",
        fetchAction: "PinUser/FETCH_PIN_NAME",
        fetchSuccessEvent: "PinUser/FETCH_PIN_NAME_0",
        fetchFailedEvent: "PinUser/FETCH_PIN_NAME_0_FAIL"
      }),
      /** 是否固定在顶部 */
      fixedTop: false,
      /** 客服二维码 */
      ServiceQR,
      getPinliteRegister,
      getPinliteLogin,
      goPinliteRegister,
      goPinliteLogin,
      ServerPinLite,
      goBoards,
      /** 注册地址 */
      registerUrl: '',
      /** 登录地址 */
      loginUrl: '',
      inputPlaceholder: '输入搜索词',
      isHistoryVal: false,
      timmer: null,
      siteListOps: {
          pinSrc: `${process.env.CDN_XCX_PATH}/p/search/edit/p_logo_001.png`,
          pinSrcHover: `${process.env.CDN_XCX_PATH}/p/search/edit/p_logo_002.png`,
          behanceSrc: `${process.env.CDN_XCX_PATH}/p/search/edit/search_behance_icon.png?1`,
          behanceSrcHover: `${process.env.CDN_XCX_PATH}/p/search/edit/search_behance_hover_icon.png?1`,
          artstationSrc: `${process.env.CDN_XCX_PATH}/p/search/edit/search_artstation_icon.png?1`,
          artstationSrcHover: `${process.env.CDN_XCX_PATH}/p/search/edit/search_artstation_hover_icon.png?1`,
      },
    };
  },
  methods: {
    handleWrapper() {
      if (this.recommended.length || !this.kw.length) {
        return;
      }
    },
    handleHistoryChange(keyword) {
      this.kw = keyword;
      this.disableSearch();
    },
    isHistory(word) {
      if (word.length) {
        this.isHistoryVal = true;
      } else {
        this.isHistoryVal = false;
      }
    },
    hotWordsList(keyword) {
      this.kw = keyword;
      this.disableSearch();
    },
    /** 未登录点击输入框处理 */
    handleClick(e: MouseEvent) {
      if (this.showRegisterDialog) {
        e.stopPropagation();
        e.preventDefault();
        // BigBigWork.emit('ShowRegisterDialog');
        BigBigWork.emit('OpenDialogLogin');
      }
    },
    /** 挂载组件后调用 */
    onMount() {
      this.clear();
    },
    disableSearch() {
      BigBigWork.emit('isInput',false)
      this.searchActive = false;
      this.$refs.kwInput.blur();
    },
    onScroll(e) {
      try {
          if (!this.keywordList.length){
              const domRect = this.$refs.wrapper.getBoundingClientRect();
              if (domRect.top < 0) {
                  !this.fixedTop && (this.fixedTop = true);
                  if(this.$listeners['getFixed']) this.$emit('getFixed', true);
              } else {
                  this.fixedTop && (this.fixedTop = false);
                  if(this.$listeners['getFixed']) this.$emit('getFixed', false)
              }
          }else{
              // 存在补充词时跟屏逻辑
              const scrollDis = window.scrollY || document.documentElement.scrollTop
              const height = this.$store.getters['Message/hasMassage'] ? 156 + 12 + 60 : 156 + 12

              if (scrollDis<height) {
                  this.fixedTop && (this.fixedTop = false);
                  if(this.$listeners['getFixed']) this.$emit('getFixed', false)
              } else {
                  !this.fixedTop && (this.fixedTop = true);
                  if(this.$listeners['getFixed']) this.$emit('getFixed', true);
              }
          }
      } catch (e) {
        throw e;
      }
    },
    /** 清空搜索 */
    reset() {
      this.$router.push({query: {}});
    },
    clear() {
      this.searchActive = false;
      this.recommended = [];
      this.recommendSearchWord = "";
    },
    /** 关键词变更时调用 */
    async onKeywordChange(words) {
      try {
        if (!words) {
          const kw = trim(this.kw);
          await this.leaveInputMode();
          if (kw) {
            this.$router.push({path: "/tupian", query: {kw: kw}});
          }
        } else {
          if (words.board_title && words.uuid) {
            this.disableSearch();
            _hmt.push(['_trackEvent', 'pinlite搜索推荐', '点击', '图片集'])
            this.goBoards(this.$store, words.uuid, words.board_title);
            return;
          } else if (words.more) {
            this.disableSearch();
            _hmt.push(['_trackEvent', 'pinlite搜索推荐', '点击', '更多board'])
            this.goBoards(this.$store,``, words.more);
            return;
          } else {
            _hmt.push(['_trackEvent', 'pinlite搜索推荐', '点击', '联想词'])
            this.$router.push({path: "/tupian", query: {kw: words}});
            this.disableSearch();
          }
        }
      } catch (e) {
        throw e;
      }
    },
    /** 当按下键盘时调用 */
    onKeydown(event: KeyboardEvent) {
      window.setTimeout(() => {
        // 等待输入完成后调用
        this.onKeywordChange();
      })
    },
    /** 进入输入模式 */
    enterInputMode() {
      if (!this.login) {
        // goPinliteRegister(this.loginRedirect || this.$router.fullPath);
        BigBigWork.emit('OpenDialogLogin');
        return;
      }
      this.searchActive = true;
      BigBigWork.emit('isInput',true)
      if (this.timmer) {
        clearTimeout(this.timmer)
      }
      this.timmer = setTimeout(() => {
        this.onInputChange();
      }, 300)
    },
    focusInputMode() {
      BigBigWork.emit('onFocus')
    },
    /** 退出输入模式 */
    leaveInputMode() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.disableSearch();
          return resolve();
        }, 200);
      })

    },
    /** 点击翻译词时调用 */
    handleTranslateClick(word) {
      console.log(word);
      _hmt.push(['_trackEvent', this.resource, `点击翻译词`, this.translatedWords[0]]);
        statsDzActionClick ( {
            store:this.$store,
            action_type: `PL搜索-点击翻译词`,
            source: `pc`,
            remark: word
        } ) ;
    },
    /** 选中推荐词时调用 */
    handleChangeKeyword(keyword) {
      console.log('44444', keyword)
      if (keyword !== undefined && keyword.board_title && keyword.uuid) { // 图片集
        this.kw = keyword.board_title;
      } else if (keyword.more) {  // 查看更多图片集
        this.kw = keyword.more;
      } else { // 正常搜索
        this.kw = keyword;
      }
    },
    toHome() {
      if (!this.login) {
        goPinliteRegister(this.loginRedirect || this.$router.fullPath);
        return;
      }
      if( this.$route.name === "home" && !this.$route.query.kw ){
        BigBigWork.emit("refreshHome")
      }else{
        this.$router.push("/");
      }
      // this.$refs.kwInput.focus();

    },
    /** 每当输入字符时调用 */
    async onInputChange() {
      try {
        // 关键词变动时通知父组件
        this.$emit("KeywordChange");

        // 获取推荐词,推荐board
        const [wordList, boardList] = await Promise.all([await this.fetchRecommend(this.kw), await this.fetchRecommendBoard(this.kw)])
        if (wordList !== undefined) {
          this.$set(this, "recommended", wordList);
        }
        if (boardList) {
          this.$set(this, "recommendBoardsList", boardList);
        }
      } catch (e) {
        this.recommended = [];
        this.recommendBoardsList = []
        throw e;
      }

    },
    /** 获取Pinterest推荐词 */
    async fetchRecommend(kw: string): string[] {
      try {
        const _self = this;
        this.recommendedCancel && this.recommendedCancel();
        const searchWord = kw.trim()
        if (!searchWord) {
          return Promise.resolve([]);
        }
        // 获取令牌， 如果出错就报错
        const [signErr, sign] = await this.$store.dispatch('PinUser/FETCH_SIGN');
        if (signErr) {
          return Promise.resolve([]);
        }
        /** 如果没有获取过pinterest用户名，就先去获取一个 */
        await this.getUserName();
        const token = await getTokenCrossDomain();
        /** 检查缓存是否有数据 */
        const cache = CacheItems.get(`searchRecWord:${searchWord}`)
        if (cache) {
          return cache
        }
        /** 检查搜索词是否搜索过无结果 */
        const cacheSearchNoRec = JSON.parse(Cache.get('searchNoRec') || '[]')
        if (kw.trim().length > 1 && cacheSearchNoRec.length && cacheSearchNoRec.find(item => kw.trim().startsWith(item))) {
          return []
        }
        return axios({
          method: "GET",
          url: this.$store.getters["PinUser/pinNode"] + `/search/recommend`,
          params: {
            name: this.$store.state.PinUser.commonUser,
            keyword: this.kw.trim(),
            sign: this.$store.state.PinUser.sign,
            token
          },
          cancelToken: new axios.CancelToken(function executor(c) {
            //取消还未完成的请求
            _self.recommendedCancel = c;
          })
        }).then(res => {
          this.recommendedCancel = null;
          if (res.status === 200 && res.data.status === 200) {
            /** 修改推荐词下拉加粗文字用关键字 */
            this.recommendSearchWord = kw.trim();
            /** 无结果的词缓存起来 */
            if (this.kw.trim().length > 1 && res.data.keywords.length === 0) {
              let searchNoRec = JSON.parse((Cache.get('searchNoRec') || '[]'))
              searchNoRec.push(this.kw.trim())
              Cache.set('searchNoRec', JSON.stringify(searchNoRec))
            }
            if (res.data.keywords.length) {
              CacheItems.set(`searchRecWord:${searchWord}`, res.data.keywords as any)
            }
            return res.data.keywords as string[];
          } else {
            throw new Error(JSON.stringify(res));
          }
        });
      } catch (e) {
        this.recommendedCancel = null;
        throw e;
      }
    },
    async fetchRecommendBoard(kw: string): string[] {
      try {
        const _self = this;
        const searchWord = kw.trim()
        if (!searchWord) {
          return Promise.resolve([]);
        }
        const token = await getTokenCrossDomain();
        /** 检查缓存 */
        const cache = CacheItems.get(`wordRecBoards:${searchWord}`)
        if (cache) {
          return cache
        }
        /** 检查搜索词是否在无结果搜索词列表中 */
        const cacheWordNoRecBoards = JSON.parse((Cache.get('wordNoRecBoard') || '[]'))
        const isFind = cacheWordNoRecBoards.find(item => searchWord.startsWith(item))
        if (searchWord.length > 1 && cacheWordNoRecBoards.length && isFind) {
          return []
        }
        return axios({
          url: ServerRecommendWord + '/wordsTopicSearch',
          method: "get",
          params: {
            word: searchWord,
            user_token: token
          },
          cancelToken: new axios.CancelToken(function executor(c) {
            //取消还未完成的请求
            _self.recommendedCancel = c;
          }),
          timeout: 1000,
        }).then(res => {
          //取消还未完成的请求
          this.recommendedCancel = null;
          if (res.status === 200 && res.data.code === 200) {
            let data = JSON.parse(JSON.stringify(res.data.data))
            if (data && data.board) {
              let boardLists = JSON.parse(JSON.stringify(data.board));
              CacheItems.set(`wordRecBoards:${searchWord}`, boardLists)
              return boardLists;
            } else {
              /** 无结果的词缓存下来 */
              const wordNoRecBoard = JSON.parse((Cache.get('wordNoRecBoard') || '[]'))
              wordNoRecBoard.push(searchWord)
              Cache.set('wordNoRecBoard', JSON.stringify(wordNoRecBoard))
            }
          } else {
            throw new Error(JSON.stringify(res));
          }
        });
      } catch (e) {
        this.recommendedCancel = null;
        throw e;
      }
    },
      async pinCli(options = {isFromBtn: true}) {
          const list = this.$store.state.AiEntry.list
          const item = list.find( item => item.action_key === 'pl_search_p' )
          statsDzToAi(this.$store, item.from_text, '');

          Scripts.CheckPinterest(this.$store, "你的pinlite特权用完啦，<span class=\"red\">开通VIP</span>，就能立即使用了！", '点击pinterest-首页-搜索框右侧')
      },
      isShowButton(name){
          const list = this.$store.state.AiEntry.list
          const item = list.find( item => item.action_key === name )
          if (!item){
              // 不存在对应action_key，按钮隐藏
              return false
          } else if (item && !item.show_name){
              // 存在对应action_key且item.show_name为空时，显示按钮
              return true
          } else{
              // 存在对应action_key且item.show_name等于phone且已绑定手机号时，显示按钮
              return !!(item && item.show_name === 'phone' && this.$store.state.UserInfo.phone);
          }
      }
  },
  computed: {
    wrapperStyle(){
      return {
        ...this.headerStyle,
        ...{
          background: this.fixedTop ? "transparent" : "#fff"
        }
      }
    },
    /** 根据登录状态，显示登录注册按钮或者反馈建议(SSR使用，不能使用用户信息)*/
    showLogin() {
      if (this.loginState !== undefined) {
        return this.loginState;
      } else {
        return this.$store.getters.token;
      }
    },
    login() {
      return this.$store.getters["UserInfo/userReady"];
    },
    //height() {
    //  let height = 120;
    //  //if (this.$store.state.UserInfo?.id <= -1) {
    //  //  height -= 40;
    //  //}
    //  return height;
    //},
    style() {
      let result = [this.$style.SearchTop];
      if (this.fixedTop) {
        result.push(this.$style.fixed);
      }
      if ( this.searchActive ){
        result.push(this.$style.active);
      }
      return result;
    }
  },
  watch: {
    keyword: function (newVal, oldVal) {
      if (newVal !== this.kw) {
        this.kw = newVal;
      }
    },
    "$route": function (newVal: Route, oldVal: Route) {
      if (newVal === oldVal) return;
      // if (newVal.matched[0].path == '/tupian' || newVal.matched[0].path == '') {
      //   this.$refs.kwInput.focus();
      // }
    }
  }
};
</script>
<style lang="scss" src="./SearchInput.scss" module></style>
