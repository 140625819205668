var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isShow,
        "custom-class": "cDialogPaySuccessImg",
        "append-to-body": true,
        "close-on-click-modal": false,
        fullscreen: true,
        "modal-append-to-body": true,
        "show-close": true,
        top: "0px",
      },
      on: { close: _vm.onClose },
    },
    [
      _c("div", { class: [_vm.$style["pay-success"], "font-medium"] }, [
        _c("img", {
          class: _vm.$style["pay-success-img"],
          attrs: {
            src: _vm.process.env.CDN_DZ_PATH + "/3w/dz/tc_ffcg.png",
            alt: "支付成功",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }