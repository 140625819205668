import { checkSupportWEBP, getToken, toRelativeProtocol, emptyImg } from "@scripts/tools" ;
import { $axios } from "@scripts/Axios" ;
import { ServerImageSearch, ServerMainSearchNode, ServerPService } from "@constants/servers" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import type { ResultItem } from "@scripts/resultItem" ;
import isNumber from "lodash-es/isNumber" ;
import random from "lodash-es/random" ;
import { Base64 } from "js-base64" ;
import { Store } from "vuex" ;
import qs from 'qs' ;
import axios from "axios" ;
import { getEncryptUrl, getNewEncryptUrl, getRabbitEncryptUrl, getRabbitEncryptUrl2 } from "@constants/servlet" ;
import { loadData } from "@scripts/LocalStorage" ;
const FileType = require ( `file-type/browser` ) ;
const allSettled = require ( `promise.allsettled` ) ;
/** 图片样式类型 **/
export type ProxyImageStyle =
    `thumb`
    | `thumbAuto`
    | `tabulationBigImage`
    | `bigImage`
    | `groupThumb`
    | `tabulation`
    | `tabulation2`
    | `boardTabulation`
    | `boardrightTabulation`
    | `boardrightTabulation2`
    | `boardTabulationNew`
    | `boardTabulation2new`
    | `boardrightTabulationNew`
    | `boardrightTabulation2new`
    | `newBigImage`
    | `pin`
    | `board_tabulation`
    | `board_tabulation_2`
    | `board_tabulation_new`
    | `board_tabulation_2_new`
    | `boardright_tabulation`
    | `boardright_tabulation_2`
    | `tabulation_2`
    | `pc_bimg_vip`
    | `pc_bimg_vip_gif`
    | `pc_bimg_novip`
    | `pc_bimg_novip_gif`
    | `newBigImageDt`
    | `pc_h_160`
    | `pc_h_160_2x`
    | `t_pin_origin1`
    | `t_pin_origin2`
    | `pc_dv_t_jpg`
    | `pc_d_t_png`
    ;

/** 图片样式字符串 */
export type ProxyImageStyleList = Record<ProxyImageStyle, string> ;
/** 代理服务器类型 */
/** 代理服务器类型 */
export type ProxyType = `primary` | `second` ;

/** 加速节点单例类 */
export class SearchProxy {
    /**
     * @description 是否处于获取节点服务器的状态
     */
    static loading:boolean = false;
    /**
     * @description 是否处于获取下载服务器的状态
     */
    static serverloading:boolean = false;
    /**
     * @description 加速节点列表
     */
    static proxyList = ( ):Array<string> => {
        const currentProxy = SearchProxy.proxyNode[SearchProxy.currentListIndex].list ;
        if ( currentProxy.length ) {
            return currentProxy ;
        } else {
            const proxy = SearchProxy.proxyNode[0].list.length
                ? SearchProxy.proxyNode[0].list
                : SearchProxy.proxyNode[1].list.length
                    ? SearchProxy.proxyNode[1].list
                    : [] ;
            return proxy ;
        }
    };

    /** 当前节点列表 */
    static currentListIndex = 0;

    /**
     * @description 分节点下载列表
     */
    static proxyNode:Array<{name:string, list:string[]}> = [
        { name: `primary`, list: [] },
        { name: `second`, list: [] }
    ];

    /**
     * @description 国内下载列表
     */
    static serverList = []

    /** 是否支持webp */
    static isSupportWEBP:boolean = false;
    /** 获取图片样式字符串 */
    public static get imageStyle ():ProxyImageStyleList {
        const ImageStyles: ProxyImageStyleList = {
            pin: `pin`,
            thumb: this.isSupportWEBP ? `s_wh_w` : `s_wh_o`,
            thumbAuto: this.isSupportWEBP ? `s_w_w` : `s_w_o`,
            bigImage: this.isSupportWEBP ? `d_w_w` : `d_w_o`,
            groupThumb: this.isSupportWEBP ? `d_wh_w` : `d_wh_o`,
            tabulation: this.isSupportWEBP ? `pc_236_webp` : `pc_236_jpg`,
            tabulationBigImage: this.isSupportWEBP ? `pc_236_webp` : `pc_236_jpg`,
            tabulation2: this.isSupportWEBP ? `pc_236_webp_2x` : `pc_236_jpg_2x`,
            boardTabulation: this.isSupportWEBP ? `pc_200_webp` : `pc_200_jpg`,
            boardrightTabulation: this.isSupportWEBP ? `pc_100_webp` : `pc_100_jpg`,
            boardrightTabulation2: this.isSupportWEBP ? `pc_200_webp` : `pc_200_jpg`,
            boardTabulationNew: this.isSupportWEBP ? `pc_160_240_webp` : `pc_160_240_jpg`,
            boardTabulation2new: this.isSupportWEBP ? `pc_160_240_2_webp` : `pc_160_240__2_jpg`,
            boardrightTabulationNew: this.isSupportWEBP ? `pc_78_119_webp` : `pc_78_119_jpg`,
            boardrightTabulation2new: this.isSupportWEBP ? `pc_78_119_2_webp` : `pc_78_119_2_jpg`,
            newBigImage: this.isSupportWEBP ? `pc_680_webp` : `pc_680_jpg`,
            newBigImageDt: this.isSupportWEBP ? `dt_680_webp` : `dt_680_jpg`,
            board_tabulation: this.isSupportWEBP ? `pc_200_webp` : `pc_200_jpg`,
            board_tabulation_2: this.isSupportWEBP ? `pc_200_webp_2x` : `pc_200_jpg_2x`,
            board_tabulation_new: this.isSupportWEBP ? `pc_160_240_webp` : `pc_160_240_jpg`,
            board_tabulation_2_new: this.isSupportWEBP ? `pc_160_240_2_webp` : `pc_160_240__2_jpg`,
            boardright_tabulation: this.isSupportWEBP ? `pc_100_webp` : `pc_100_jpg`,
            boardright_tabulation_2: this.isSupportWEBP ? `pc_200_webp` : `pc_200_jpg`,
            tabulation_2: this.isSupportWEBP ? `pc_236_webp_2x` : `pc_236_jpg_2x`,
            pc_bimg_vip: this.isSupportWEBP ? `pc_dv_webp` : `pc_dv_jpg`,
            pc_bimg_vip_gif: `pc_dv_gif`,
            pc_bimg_novip: this.isSupportWEBP ? `pc_d_webp` : `pc_d_jpg`,
            pc_bimg_novip_gif: `pc_d_gif`,
            pc_h_160: this.isSupportWEBP ? `pc_h_160_webp` : `pc_h_160_jpg`,
            pc_h_160_2x: this.isSupportWEBP ? `pc_h_160_webp_2x` : `pc_h_160_jpg_2x`,
            t_pin_origin1: this.isSupportWEBP ? `tpin_680_webp` : `tpin_680_jpg`,
            t_pin_origin2: this.isSupportWEBP ? `tpin_680_webp` : `tpin_680`,
            pc_dv_t_jpg: this.isSupportWEBP ? `pc_dv_t_webp` : `pc_dv_t_jpg`,
            pc_d_t_png: this.isSupportWEBP ? `pc_dv_t_webp` : `pc_dv_t_png`
        } ;
        return ImageStyles ;
    }

    /**
     * 获取代理服务器列表 v2,可以获取主从两套服务器地址
     * @see http://doc.bigbigwork.com/docs/ply/4190
     */
    public static async fetchImgProxy2 ( force:boolean = false ):Promise<string[]> {
        try {
            // 已初始化
            if ( SearchProxy.proxyList ().length && !force ) {
                return SearchProxy.proxyList () ;
            }
            if ( SearchProxy.loading ) {
                return new Promise ( ( resolve, reject ) => {
                    BigBigWork.once ( `SearchProxy/fetchImgProxy`, ( list ) => {
                        resolve ( list ) ;
                    } ) ;
                    BigBigWork.once ( `SearchProxy/fetchImgProxy_FAIL`, ( e ) => {
                        reject ( e ) ;
                    } ) ;
                } ) ;
            } else {
                // 从服务器加载
                SearchProxy.loading = true ;
                const res = await $axios ( {
                    method: `GET`,
                    url: `https:` + ServerPService + `/site/get-img-proxy2.htm`,
                    params: {
                        isAbroad: 1
                    }
                } ) ;
                const proxyNodes = this.handleProxyRes ( res.data ) ;
                SearchProxy.loading = false ;
                SearchProxy.proxyNode[0].list = proxyNodes.primary ;
                SearchProxy.proxyNode[1].list = proxyNodes.second ;
                BigBigWork.emit ( `SearchProxy/fetchImgProxy`, SearchProxy.proxyList () ) ;
                return SearchProxy.proxyList () ;
            }
        } catch ( e ) {
            BigBigWork.emit ( `SearchProxy/fetchImgProxy_FAIL`, e ) ;
            throw e ;
        }
    }

    /** 处理域名 */
    static handleProxyRes ( proxyList ) {
        const primary = [] ;
        const second = [] ;
        proxyList.forEach ( val => {
            // 默认节点
            if ( val.is_default === 1 ) {
                primary.push ( val.ip ) ;
            } else {
                second.push ( val.ip ) ;
            }
        } ) ;
        return { primary, second } ;
    }

    /** 获取代理服务器列表 */
    public static fetchImgProxy ( ):Promise<string[]> {
        console.warn ( `fetchImgProxy已被弃用` ) ;
        return new Promise ( ( resolve, reject ) => {
            const proxyList = SearchProxy.proxyList () ;
            if ( proxyList.length ) {
                resolve ( proxyList ) ;
            } else if ( SearchProxy.loading ) {
                BigBigWork.once ( `SearchProxy/fetchImgProxy`, () => {
                    resolve ( proxyList ) ;
                } ) ;
                BigBigWork.once ( `SearchProxy/fetchImgProxy_FAIL`, () => {
                    resolve ( [] ) ;
                } ) ;
            } else {
                SearchProxy.loading = true ;
                $axios ( {
                    method: `GET`,
                    url: `https:` + ServerPService + `/site/get-img-proxy.htm`,
                    params: {
                        isAbroad: 1
                    }
                } ).then ( res => {
                    if ( res.status === 200 ) {
                        const list:string[] = res.data.split ( `,` ) ;
                        SearchProxy.proxyNode[0].list = list.filter ( ( proxy ) => {
                            return proxy !== `` ;
                        } ) ;
                        BigBigWork.emit ( `SearchProxy/fetchImgProxy` ) ;
                        resolve ( SearchProxy.proxyNode[0].list ) ;
                    } else {
                        BigBigWork.emit ( `SearchProxy/fetchImgProxy_FAIL` ) ;
                        reject ( JSON.stringify ( res ) ) ;
                    }
                } ).finally ( () => {
                    SearchProxy.loading = false ;
                } ).catch ( e => {
                    reject ( JSON.stringify ( e ) ) ;
                } ) ;
            }
        } ) ;
    }

    /**
     * @description 取得图片国内服务器地址
     */
    public static async fetchImgDomesticServer ():Promise<string[]> {
        if ( SearchProxy.serverList.length ) {
            return Promise.resolve ( SearchProxy.serverList ) ;
        }
        if ( this.serverloading ) {
            return await new Promise ( ( resolve, reject ) => {
                BigBigWork.once ( `SearchProxy/fetchImgDomesticServer`, () => {
                    return Promise.resolve ( this.serverList ) ;
                } ) ;
                BigBigWork.once ( `SearchProxy/fetchImgDomesticServer_FAIL`, () => {
                    return Promise.resolve ( [] ) ;
                } ) ;
            } ) ;
        } else {
            this.serverloading = true ;
            return $axios ( {
                url: `https:` + ServerPService + `/site/get-img-domestic-proxy.htm`
            } ).then ( res => {
                if ( res.status === 200 ) {
                    const list:string[] = res.data.split ( `,` ) ;
                    SearchProxy.serverList = list.filter ( ( proxy ) => {
                        return proxy !== `` ;
                    } ) ;
                    BigBigWork.emit ( `SearchProxy/fetchImgDomesticServer` ) ;
                    return SearchProxy.serverList ;
                } else {
                    BigBigWork.emit ( `SearchProxy/fetchImgDomesticServer_FAIL` ) ;
                    throw new Error ( JSON.stringify ( res ) ) ;
                }
            } ).finally ( () => {
                this.serverloading = false ;
            } ) ;
        }
    }

    /** 获取随机代理 */
    public static randomProxy ( ) {
        const proxyList = SearchProxy.proxyList () ;
        if ( proxyList.length > 1 ) {
            const index = random ( 0, proxyList.length - 1 ) ;
            return proxyList[index] ;
        } else {
            return proxyList[0] ;
        }
    }

    /** 取得一个随机国内节点 */
    public static randomImageServer () {
        if ( SearchProxy.serverList.length > 1 ) {
            const index = random ( 0, SearchProxy.serverList.length - 1 ) ;
            const randomServer = SearchProxy.serverList[index] ;
            return randomServer ;
        } else {
            return SearchProxy.serverList[0] ;
        }
    }

    /** 获取oss图片地址 */
    public static getImgUrl ( filename:string, imageStyle?:ProxyImageStyle, item?:ResultItem, proxy?:string ):string {
        if ( !filename ) return `` ;
        let src = `` ;
        const reg = new RegExp ( /^(http:|https:)?\/\/(mimg|(csimg|simg)([1-9]|1[0-3]))?[.].*/ ) ;
        // const simgArry = ['simg', 'simg2', 'simg3', 'simg4', 'simg7', 'simg8', 'simg9'];
        // let regSimg = null;
        if ( proxy === `false` || ( item && item.type === `search` ) || reg.test ( filename ) ) {
            // 阿里云上的图片
            src = filename ;
            if ( imageStyle ) {
                /* for (let i = 0;i < simgArry.length;i++ ) {
                    regSimg = new RegExp ( "^(http:|https:)?\/\/"+simgArry[i]+"?[.].*") ;
                    if ( regSimg.test ( src ) ) {
                        src = src.replace('simg', 'csimg');
                    }
                } */
                src += `?x-oss-process=style/` + this.imageStyle[imageStyle] ;
            }
        } else {
            // 香港云图片
            src = `//` + ( proxy || ( item && item.proxy ) ) + `/download/bigbigwork.jpg?` + `&url=` + encodeURIComponent ( filename ) ;
            if ( imageStyle ) {
                src += `&style=` + this.imageStyle[imageStyle] ;
            }
        }
        return src ;
    }

    /** 获取图片多重尺寸 */
    public static getMultiSrc ( item:ResultItem, url, imageStyle1:ProxyImageStyle = `tabulation`, imageStyle2:ProxyImageStyle = `tabulation_2` ):string {
        if ( url === emptyImg ) return url ;
        let srcset = `` ;
        srcset = toRelativeProtocol ( SearchProxy.getImgUrl ( url, imageStyle1, item ) ) + ` 1x,` ;
        srcset += toRelativeProtocol ( SearchProxy.getImgUrl ( url, imageStyle2, item ) ) + ` 2x,` ;
        return srcset ;
    }

    /** 获取图片多重尺寸(背景图片用) */
    public static getMultiBgSrc ( item:ResultItem, url, prefix:string = ``, imageStyle1:ProxyImageStyle = `tabulation`, imageStyle2:ProxyImageStyle = `tabulation_2` ):string {
        if ( url === emptyImg ) return url ;
        let srcset = `` ;
        if ( prefix !== null ) {
            srcset += `${prefix}image-set(url("${toRelativeProtocol ( SearchProxy.getImgUrl ( url, imageStyle1, item ), `https://` )}") 1x, url("${toRelativeProtocol ( SearchProxy.getImgUrl ( url, imageStyle2, item ), `https://` )}") 2x ),` ;
        }
        srcset += `url('${toRelativeProtocol ( SearchProxy.getImgUrl ( url, imageStyle1, item ) )}')` ;

        return srcset ;
    }
} ;
/** 初始化代理服务器 */
export const CreateProxy = async ( store ?:Store<any> ) => {
    try {
        // 设置为上次的节点
        const proxyType = loadData ( `ProxyType`, `default` ) || `primary` ;
        if ( proxyType !== `primary` ) {
            SearchProxy.currentListIndex = 1 ;
            store && store.commit ( `PinUser/setProxyType`, proxyType ) ;
        }
        const [isSupportWEBP, fetchImgDomesticServer, fetchImgProxy2] = await allSettled ( [
            CheckSupportWEBP (),
            SearchProxy.fetchImgDomesticServer (),
            SearchProxy.fetchImgProxy2 ()
        ] ) ;
        if ( isSupportWEBP.status === `fulfilled` ) {
            SearchProxy.isSupportWEBP = isSupportWEBP.value ;
        }
        return Promise.resolve ( true ) ;
    } catch ( e ) {
        console.error ( e ) ;
        return Promise.resolve ( true ) ;
    }
} ;
/** 检查是否支持webp */
export const CheckSupportWEBP = async () => {
    const isSupport = await checkSupportWEBP () ;
    SearchProxy.isSupportWEBP = isSupport ;
    return isSupport ;
} ;
/** 处理代理节点错误
 * @param store
 **/
export const handleProxyError = ( store:Store<any> ) => {
    // 订阅错误
    store.subscribe ( async ( mutation, state ) => {
        if ( mutation.type === `PinUser/addProxyErrorNum` ) {
            if ( state.PinUser.proxyErrorNum >= 5 && store.state.PinUser.proxyType === `primary` ) {
                store.commit ( `PinUser/setProxyType`, `second` ) ;
                if ( SearchProxy.proxyNode[1].list.length ) {
                    // 如果有备用服务器，统计以下
                    _hmt.push ( [`_trackEvent`, `pinlite`, `开启备用`, `显示有问题`] ) ;
                }
            }
        }
    } ) ;
} ;
/** 取得图片版权信息 */
export const getCopyrightInfo = ( item:ResultItem ):Promise<ResultItem> => {
    // 如果已经取得过图片详情页信息，则返回
    if ( !!item.copyrightInfo && !!Object.keys ( item.copyrightInfo ).length ) {
        return Promise.resolve ( item ) ;
    }
    return $axios ( {
        url: ServerMainSearchNode + `/search-node/` + item.siteInfo.servletUrl + `?action=COPYRIGHT&url=` + encodeURIComponent ( item.pageUrl )
    } ).then ( res => {
        if ( res.status === 200 ) {
            item.copyrightInfo = res.data.data ;
            return item ;
        } else {
            throw new Error ( `不能成功获取copyright信息` ) ;
        }
    } ) ;
} ;
/** 取得图片详情页信息 */
export const getLargePage = async ( item:ResultItem ):Promise<ResultItem> => {
    const token = await getToken () ;
    // 如果已经取得过图片详情页信息，则返回
    if ( item.thumbImages.length > 0 && item.groupImages.length > 0 ) {
        return Promise.resolve ( item ) ;
    }
    item.thumbImages.splice ( 0, Number.MAX_VALUE ) ;
    item.groupImages.splice ( 0, Number.MAX_VALUE ) ;

    // 如果搜索结果中直接有大图和下载图信息或是issue，则不用ajax取得大图信息
    if ( item.bUrl && item.detailsUrl ) {
        item.thumbImages = item.thumbImages.concat ( item.bUrl.split ( `,` ) ) ;
        item.groupImages = item.groupImages.concat ( item.detailsUrl.split ( `,` ) ) ;
        if ( !item.siteInfo.isMultiImg && !item.detailsUrl ) {
            item.detailsUrl = item.groupImages[0] || item.url ;
        }
        return Promise.resolve ( item ) ;
    } else if ( item.siteInfo.id === 1870 ) {
        // issuu
        item.thumbImages = item.thumbImages.concat ( item.bUrl.split ( `,` ) ) ;
        item.groupImages = item.thumbImages.concat ( [] ) ;
        return Promise.resolve ( item ) ;
    } else {
        // 去服务器取得图片详情页信息
        $axios ( {
            url: ServerMainSearchNode + `/search-node/` + item.siteInfo.servletUrl + `?action=LARGE&page=` + ( encodeURIComponent ( item.pageUrl ) || `` ) + `&url=` + encodeURIComponent ( item.url ) + `&w=` + item.w + `&h=` + item.h + `&dashisousuo=` + token
        } ).then ( res => {
            const data = res.data ;
            // 如果有项目组图itemList（新版添加的）
            if ( data.itemList ) {
                for ( const i in data.itemList ) {
                    item.thumbImages.push ( data.itemList[i].bUrl ) ;
                    item.groupImages.push ( data.itemList[i].detailsUrl ) ;
                }
            } else {
                // 否则从bUrl和detailUrl获取组图
                const bUrl = item.bUrl || data.bUrl || `` ;
                const detailsUrl = item.detailsUrl || data.detailsUrl || `` ;
                item.thumbImages = item.thumbImages.concat ( bUrl.split ( `,` ) ) ;
                item.groupImages = item.groupImages.concat ( detailsUrl.split ( `,` ) ) ;
                item.author = item.author ? item.author : data.author ;
                if ( !item.siteInfo.isMultiImg ) {
                    if ( data.w && data.h ) {
                        item.w = data.w ;
                        item.h = data.h ;
                    }
                    if ( !item.bUrl && data.bUrl ) {
                        if ( data.bUrl.indexOf ( `http` ) === -1 ) {
                            item.bUrl = `http:` + data.bUrl ;
                        } else {
                            item.bUrl = data.bUrl ;
                        }
                    }
                    if ( !item.detailsUrl ) {
                        if ( data.detailsUrl && data.detailsUrl.indexOf ( `http` ) === -1 ) {
                            item.detailsUrl = `http:` + ( data.detailsUrl ) ;
                        } else {
                            item.detailsUrl = data.detailsUrl ;
                        }
                    }
                }
            }
            return Promise.resolve ( item ) ;
        } ) ;
    }
} ;
/** 获取图片下载地址 */
export const getDownloadImageUrl = async ( item:ResultItem, index?:number, useProxy?:boolean, store?: Store<any> ):Promise<string> => {
    console.log ( `downloadItem`, item, item.downloadUrl ) ;
    const token = await getToken () ;
    await SearchProxy.fetchImgProxy2 () ;
    let signErr ;
    let sign = `` ;
    if ( store ) {
        [signErr, sign] = await store.dispatch ( `PinUser/FETCH_SIGN` ) ;
    }
    if ( signErr ) throw signErr ;
    // 获取图片详细信息后调用
    const onload = async ( needProxy:boolean ):Promise<string> => {
        console.log ( needProxy, `needProxy状态` ) ;
        if ( needProxy ) {
            await SearchProxy.fetchImgProxy2 () ;
            // 从聚合首页收藏过来的
            if ( !item.detailsUrl && item.type === `metaToSearch` ) {
                item.detailsUrl = item.bUrl ;
            }
            // 判断数据库里是否有原始图，没有就去原网站取得更大图
            if ( item.detailsUrl ) {
                // 如果数据库没有原始图，聚合收藏过来的detailsUrl会带 "http://@proxy@/download/" (这个在ResultItem.toBoard()里面定义的)
                if ( item.detailsUrl.indexOf ( `//@proxy@/download/` ) > -1 ) {
                    // 因为insertImg 收藏缺少item.siteInfo.servletUrl 以及item.url，后端不加字段，所以才这样糊墙，把整串都带过来
                    // item.downloadUrl = `//` + ( item.proxy || SearchProxy.randomProxy () ) + `/download/` + item.detailsUrl.substring ( 24 ) + `&dashisousuo=` + token ;
                    const proxyDownloadUrl = item.detailsUrl.replace ( `@proxy@`, item.proxy || SearchProxy.randomProxy () ) ;
                    item.downloadUrl = proxyDownloadUrl + `&dashisousuo=` + token ;
                } else {
                    // 国外下载
                    const detailsUrl = item.type == `search` ? item.detailsUrl : item.detailsUrl.split ( `,` )[0] ;
                    item.downloadUrl = `//` + ( item.proxy || SearchProxy.randomProxy () ) + `/download/imgNew?jhk-d=1&base64Url=` + Base64.encode ( encodeURIComponent ( detailsUrl ) ) + `&dashisousuo=` + token ;
                }
            } else {
                item.downloadUrl = `//` + ( item.proxy || SearchProxy.randomProxy () ) + `/download/` + item.siteInfo.servletUrl + `?action=LARGE&page=` + encodeURIComponent ( item.pageUrl ) + `&url=` + encodeURIComponent ( item.url ) + `&w=` + item.w + `&h=` + item.h + `&dashisousuo=` + token ;
            }
        } else {
            await SearchProxy.fetchImgDomesticServer () ;
            const randomServer = SearchProxy.randomImageServer () ;
            item.downloadUrl = `//` + ( item.domesticServer || randomServer ) + `/download/imgNew?jhk-d=1&base64Url=` + Base64.encode ( encodeURIComponent ( item.detailsUrl || item.bUrl ) ) + `&dashisousuo=` + token ;
        }
        return Promise.resolve ( item.downloadUrl + `&sign=${sign}` ) ;
    } ;
    const formatImage = ( needProxy:boolean ):Promise<string> => {
        // 多图下载
        if ( isNumber ( index ) ) {
            if ( item.downloadImages[index] ) {
                return Promise.resolve ( item.downloadImages[index] + `&sign=${sign}` ) ;
            }
            // 未获取过下载地址，生成下载地址
            if ( needProxy ) {
                // 需要加速
                for ( var i = 0 ; i < item.groupImages.length ; i++ ) {
                    if ( item.groupImages[i] ) {
                        item.downloadImages[i] = `//` + ( item.proxy || SearchProxy.randomProxy () ) + `/download/imgNew?jhk-d=1&base64Url=` + Base64.encode ( encodeURIComponent ( item.groupImages[i] ) ) + `&dashisousuo=` + token ;
                    } else {
                        item.downloadImages[i] = `//` + ( item.proxy || SearchProxy.randomProxy () ) + `/download/` + item.siteInfo.servletUrl + `?action=LARGE&page=` + encodeURIComponent ( item.pageUrl ) + `&url=` + encodeURIComponent ( item.thumbImages[index] ) + `&w=` + item.w + `&h=` + item.h + `&dashisousuo=` + token ;
                    }
                }
            } else {
                // 不需要加速
                for ( let i = 0 ; i < item.groupImages.length ; i++ ) {
                    item.downloadImages[i] = item.groupImages[i] ;
                }
            }
            return Promise.resolve ( item.downloadImages[index] + `&sign=${sign}` ) ;
        } else {
            // 单图下载
            if ( item.downloadUrl ) {
                // 已获取或下载地址；
                return Promise.resolve ( item.downloadUrl + `&sign=${sign}` ) ;
            } else {
                // 未获取过下载地址
                if ( item.type === `meta` && !item.detailsUrl ) {
                    // 智能聚合搜索
                    // 如果没有获取到detailsUrl则去获取图片详细信息
                    return getLargePage ( item ).then ( () => {
                        return onload ( needProxy ) ;
                    } ) ;
                } else {
                    return onload ( needProxy ) ;
                }
            }
        }
    } ;
    // 设置是否需要加速
    let needProxy = true ;
    // 有设置useProxy的优先选择useProxy
    if ( useProxy ) {
        needProxy = !!useProxy ;
        return formatImage ( needProxy ) ;
    } else {
        const noProxyReg = new RegExp ( /(.bigurl.ink\/)|(.bigbigwork.com\/)/ ) ;
        const reg = new RegExp ( /^(http:|https:)?\/\/simg([1-9]|1[0-3])?[.].*/ ) ;
        const url = item.detailsUrl || item.url || item.bUrl ;
        // 这些域名直接走国内代理
        if ( noProxyReg.test ( url ) ) {
            needProxy = false ;
            return formatImage ( needProxy ) ;
        }
        switch ( item.origin_type ) {
        case 14:
            // 如果是 OSS 不走代理，其他全走代理
            if ( reg.test ( url ) ) {
                needProxy = false ;
            } else needProxy = true ;
            return formatImage ( needProxy ) ;
            // eslint-disable-next-line no-unreachable
            break ;
        case 15:
            // 13.15 判断来源地址需不需要代理
            if ( reg.test ( url ) ) {
                needProxy = false ;
                return formatImage ( needProxy ) ;
            } else {
                return isDirectDownload ( url ).then ( res => {
                    if ( !res ) {
                        needProxy = true ;
                    } else {
                        // @ts-ignore
                        needProxy = !res.data ;
                    }
                    return formatImage ( needProxy ) ;
                } ) ;
            }
            // eslint-disable-next-line no-unreachable
            break ;
        default:
            // 出现这边的有 扩大图 大作搜索列表 聚合的图
            if ( item.expandimage ) {
                needProxy = false ;
                return formatImage ( needProxy ) ;
            } else if ( item.type === `search` ) {
                if ( reg.test ( url ) ) {
                    needProxy = false ;
                    return formatImage ( needProxy ) ;
                } else {
                    return isDirectDownload ( url ).then ( res => {
                        if ( !res ) {
                            needProxy = true ;
                        } else {
                            // @ts-ignore
                            needProxy = !res.data ;
                        }
                        return formatImage ( needProxy ) ;
                    } ) ;
                }
            } else {
                needProxy = true ;
                return formatImage ( needProxy ) ;
            }
        }
    }
} ;
export const isDirectDownload = ( url:string ):Promise<object> => {
    return $axios ( {
        url: ServerImageSearch + `/isdirectlink`,
        method: `POST`,
        headers: {
            'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
        },
        data: qs.stringify ( { url } )
    } ).then ( res => {
        return Promise.resolve ( res.data ) ;
    } ) ;
} ;

/**
 * 根据屏幕分辨率获取图片地址
 * @param item 图片ITEM
 * @param url 图片URL
 * @param imageStyle1 1倍屏图片
 * @param imageStyle2 2倍屏图片
 */
export const getUrlByRadio = <T extends ResultItem> (
    item: T,
    url: string,
    imageStyle1: ProxyImageStyle = `tabulation`,
    imageStyle2: ProxyImageStyle = `tabulation_2`
) => {
    const radio = window.devicePixelRatio || 1 ;
    const style = radio === 1 ? imageStyle1 : imageStyle2 ;
    return toRelativeProtocol ( SearchProxy.getImgUrl ( url, style, item ) ) ;
} ;
/**
 * @description 获取加密url及所属地信息
 * @param item
 */
export const getDownloadEncryptUrl = async ( item: ResultItem ) => {
    try {
        const res = await axios ( {
            method: `GET`,
            url: getEncryptUrl,
            params: {
                token: await getToken (),
                id: item.id
            }
        } ) ;
        if ( res.status === 200 && res.data?.code === 200 ) {
            return res.data.data ;
        }
        throw res ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 新接口 获取加密url及所属地信息
 * @param item
 */
export const getNewDownloadEncryptUrl = async ( item: ResultItem ) => {
    try {
        const res = await axios ( {
            method: `GET`,
            url: getNewEncryptUrl,
            params: {
                token: await getToken (),
                imgId: item.id
            }
        } ) ;
        if ( res.status === 200 && res.data?.status === 200 ) {
            return [null, res.data.data] ;
        }
        throw res ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 新接口 获取加密url及所属地信息 http://doc.bigbigwork.com/docs/yyx/7101
 * @param item
 */
export const getNewDownloadEncryptUrl2 = async ( item: ResultItem, verifyGreement:number ) => {
    try {
        const res = await axios ( {
            method: `GET`,
            url: getNewEncryptUrl,
            params: {
                token: await getToken (),
                imgId: item.id,
                verifyGreement
            }
        } ) ;
        if ( res.status === 200 ) {
            return [null, res.data] ;
        }
        throw res ;
    } catch ( e ) {
        return [e, null] ;
    }
} ;
/**
 * @description 新接口 获取大兔加密url及所属地信息
 * @param item
 */
export const getRabbitDownloadEncryptUrl = async ( item: ResultItem ) => {
    try {
        const res = await axios ( {
            method: `GET`,
            url: getRabbitEncryptUrl2,
            params: {
                token: await getToken (),
                imgId: item.id
            }
        } ) ;
        // if ( res.data?.status !== 200 ) {
        //     return [new Error("下载失败"), res.data] ;
        // }
        return [null, res.data] ;
    } catch ( e ) {
        return [e, { status: 500 }] ;
    }
} ;
/**
 * @description 大作图片加密下载
 * @param item
 * @param store
 */
export const toEncryptDownload = async ( item: ResultItem, store: Store<any> ) => {
    try {
        const encryptData = await getDownloadEncryptUrl ( item ) ;
        return await toDownloadEncryImg ( Object.assign ( {}, encryptData, { item, store } ) ) ;
    } catch ( e ) {
        return [e, null] ;
    }
} ;
/**
 * @description 大作图片加密下载
 * @param item
 * @param store
 */
export const toNewEncryptDownload = async ( item: ResultItem, store: Store<any> ) => {
    try {
        const [err, encryptData] = await getNewDownloadEncryptUrl ( item ) ;
        if ( err ) {
            return [err, null] ;
        } else {
            return await toDownloadEncryImg ( Object.assign ( {}, encryptData, { item, store } ) ) ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
} ;
/**
 * @description 下载加密url的图片
 * @param ops
 * @property isdirectlink false 国内下载地址，true 国外下载地址
 */

export const toDownloadEncryImg = async ( ops: {encryptData: string, isdirectlink: boolean, item: ResultItem, store: Store<any>, timeout:number, isReturnData?: boolean, showHintOpts?:object, filter?:string, bgColor?:string, id?:string} ) => {
    let url = `` ;
    let downloadTimes = 0 ;
    const signRes = await ops.store.dispatch ( `Sign/FETCH_SIGN` ) ;
    if ( signRes[0] ) return ;
    // 当前节点，阿里云
    let isShowTips = false ;
    const id = ops.id ?? ops.item.id ;
    const download = async () => {
        try {
            downloadTimes += 1 ;
            if ( ops.isdirectlink ) {
                await SearchProxy.fetchImgProxy2 () ;
                // __DEV__ ? 'http://47.242.150.74:8089/':
                const domain = ( ops.item.proxy || SearchProxy.randomProxy ( ) ) ;
                url = `//${domain}/download/encryptImg` ;
                // url = `http://47.114.87.203/download/encryptImg` ;
            } else {
                await SearchProxy.fetchImgDomesticServer () ;
                // __DEV__? '47.114.87.203':
                const domain = ( ops.item.domesticServer || SearchProxy.randomImageServer () ) ;
                url = `//${domain}/download/encryptImg` ;
                // url = `http://47.114.87.203/download/encryptImg` ;
            }
            const downloadRes: any = await axios ( {
                method: `GET`,
                url,
                responseType: `blob`,
                headers: {
                    "Content-type": `application/x-www-form-urlencoded`
                },
                onDownloadProgress: function ( res ) {
                    console.log ( `onDownloadProgress`, res ) ;
                    if ( !isShowTips && ops.showHintOpts ) {
                        BigBigWork.emit ( `ShowHint`, ops.showHintOpts ) ;
                        isShowTips = true ;
                    }
                },
                timeout: ops.timeout ?? 0,
                params: {
                    token: await getToken (),
                    'jhk-d': 1,
                    // test: __DEV__ ? 1 : undefined,
                    sign: signRes[1],
                    encryptData: ops.encryptData
                }
            } ) ;
            if ( downloadRes.status === 200 ) {
                BigBigWork.emit ( `getFailedDownloadStatus`, ops.item, false ) ;
                console.log ( ` %c 接口成功success`, `font-size:20px; color:red`, downloadRes, ops ) ;
                if ( downloadRes.data?.status === 500 ) {
                    return [null, { code: 500 }] ;
                }
                if ( ops.isReturnData ) {
                    return [null, { code: 200, data: downloadRes.data }] ;
                }
                let { ext, mime } = await FileType.fromBlob ( downloadRes.data ) ;
                if ( ext === `xml` ) {
                    ext = `svg` ;
                }
                console.log ( ` %c fileType`, `font-size:20px; color:red`, ext, mime ) ;
                /** ie兼容 */
                // @ts-ignore
                if ( navigator.msSaveOrOpenBlob ) {
                    // @ts-ignore
                    navigator.msSaveOrOpenBlob ( downloadRes.data, `jhk-${new Date ().getTime ()}.${ext}` ) ;
                    const currentItem = {
                        imgId: id,
                        progress: 100
                    } ;
                    ops.store.commit ( `Search/updateDownLoadingList`, currentItem ) ;
                } else {
                    const link = document.createElement ( `a` ) ;
                    document.body.appendChild ( link ) ;
                    const isBlob = downloadRes.data instanceof Blob ;
                    const blob = new Blob ( [downloadRes.data], { type: mime || `image/jpg` } ) ;
                    const csvUrl = URL.createObjectURL ( blob ) ;
                    link.style.display = `none` ;
                    if ( ops.filter || ops.bgColor ) {
                        const canvas = document.createElement ( `canvas` ) ;
                        const ctx = canvas.getContext ( `2d` ) ;
                        const img = new Image ( ) ;
                        canvas.width = ops.item.w ;
                        canvas.height = ops.item.h ;
                        if ( ops.filter ) {
                            ctx.filter = ops.filter ;
                        }
                        if ( ops.bgColor ) {
                            ctx.fillStyle = ops.bgColor ;
                            ctx.fillRect ( 0, 0, canvas.width, canvas.height ) ;
                        }
                        img.src = csvUrl ;
                        img.onload = function () {
                            ctx.drawImage ( img, 0, 0 ) ;
                            link.href = canvas.toDataURL ( `image/png` ) ;
                            link.download = `jhk-${new Date ().getTime ()}.${ext || `png`}` ;
                            link.click () ;
                        } ;
                    } else {
                        link.href = csvUrl ;
                        link.download = `jhk-${new Date ().getTime ()}.${ext || `jpg`}` ;
                        link.click () ;
                    }
                    window.setTimeout ( () => {
                        // 需要释放,防止内存泄漏
                        URL.revokeObjectURL ( csvUrl ) ;
                        document.body.removeChild ( link ) ;
                    }, 60 * 1000 ) ;
                    const currentItem = {
                        imgId: id,
                        progress: 100
                    } ;
                    ops.store.commit ( `Search/updateDownLoadingList`, currentItem ) ;
                }
                return [null as Error, { code: 200 }] ;
            }
            return [null as Error, { code: 500 }] ;
        } catch ( e ) {
            console.log ( `下载失败`, e ) ;
            // 如果是请求错误，返回错误码
            if ( e.isAxiosError ) {
                return [e, { code: e.response.status }] ;
            }
            return [e, { code: 500 }] ;
        }
    } ;
    const handleDownload = async () => {
        try {
            const [err, res] = await download () ;
            const downloadServer = SearchProxy.currentListIndex === 0 ? `阿里云` : `腾讯云` ;
            if ( !err && res?.code === 200 ) {
                _hmt.push ( [`_trackEvent`, `大作图片下载`, downloadServer, `成功`] ) ;
                // 统计
                BigBigWork.emit ( `DZ_STATS`, {
                    action_type: `图片下载成功`,
                    remark: `${downloadServer}`
                } ) ;
                /* const tip = new Hint ( {
                    theme: `hitbox tips_v1`,
                    child: BoardOperationTips,
                    PropsData: {
                        icon: `${process.env.CDN_XCX_PATH}/p/search/tips_warning_icon.png`,
                        p_one: `频率太快了`,
                        p_two: `请稍后再试`
                    },
                    fadeDelay: 2000
                } ) ; */
            } else {
                _hmt.push ( [`_trackEvent`, `大作图片下载`, downloadServer, `失败`] ) ;
                // 统计
                BigBigWork.emit ( `Analysis`, {
                    type: `dzStats`,
                    data: {
                        action_type: `图片下载失败`,
                        remark: `${downloadServer}`
                    }
                } ) ;
            }
            if ( ops.isdirectlink && err?.isAxiosError && downloadTimes < 2 && SearchProxy.currentListIndex === 0 ) {
                // 香港下载的，如果下载不了就换节点重试
                SearchProxy.currentListIndex = 1 ;
                // 若果是封禁用户不更换节点
                if ( res.code !== 451 ) {
                    return await handleDownload () ;
                } else {
                    return [null, { code: 451 }] ;
                }
            } else {
                if ( err ) {
                    BigBigWork.emit ( `getFailedDownloadStatus`, ops.item, true ) ;
                    console.log ( ` %c 接口失败error`, `font-size:20px; color:red`, err, res ) ;
                }
                if ( res.code === 451 ) {
                    return [null, { code: 451 }] ;
                }
                return [err, res] ;
            }
        } catch ( e ) {
            return [e, { code: 500 }] ;
        }
    } ;
    return await handleDownload () ;
} ;
export const toDownLoadImg = async ( ops: {encryptData: string, item: ResultItem, store: Store<any>, isReturnData?:boolean, timeout:number, showHintOpts?:object } ) => {
    const signRes = await ops.store.dispatch ( `Sign/FETCH_SIGN` ) ;
    if ( signRes[0] ) return ;
    const url = ops.encryptData ;
    let isShowTips = false ;
    const downloadRes: any = await axios ( {
        method: `GET`,
        url,
        responseType: `blob`,
        timeout: ops.timeout ?? 0,
        headers: {
            "Content-type": `application/x-www-form-urlencoded`
        },
        onDownloadProgress: function ( res ) {
            console.log ( `onDownloadProgress`, res ) ;
            if ( !isShowTips && ops.showHintOpts ) {
                BigBigWork.emit ( `ShowHint`, ops.showHintOpts ) ;
                isShowTips = true ;
            }
        }
    } ) ;
    console.log ( ` %c downloadRes`, `font-size:20px; color:red`, downloadRes ) ;
    if ( downloadRes.status === 200 ) {
        BigBigWork.emit ( `getFailedDownloadStatus`, ops.item, false ) ;
        console.log ( ` %c 接口成功success`, `font-size:20px; color:red`, downloadRes, ops ) ;
        if ( downloadRes.data?.status === 500 ) {
            return [null, { code: 500 }] ;
        }
        if ( ops.isReturnData ) {
            return [null, { code: 200, data: downloadRes.data }] ;
        }
        let type ;
        const index = downloadRes.data.type.indexOf ( `/` ) ;
        if ( index === -1 ) {
            return [null, { code: 500 }] ;
        } else {
            type = downloadRes.data.type.slice ( index + 1 ) ;
        }
        // @ts-ignore
        if ( navigator.msSaveOrOpenBlob ) {
            // @ts-ignore
            navigator.msSaveOrOpenBlob ( url, `jhk-${new Date ().getTime ()}.${type}` ) ;
            const currentItem = {
                imgId: ops.item.id,
                progress: 100
            } ;
            ops.store.commit ( `Search/updateDownLoadingList`, currentItem ) ;
        } else {
            const link = document.createElement ( `a` ) ;
            document.body.appendChild ( link ) ;
            const blob = new Blob ( [downloadRes.data], { type: downloadRes.data.type } ) ;
            const csvUrl = URL.createObjectURL ( blob ) ;
            console.log ( ` %c csvUrl`, `font-size:20px; color:blue`, csvUrl ) ;
            link.style.display = `none` ;
            link.href = csvUrl ;
            link.download = `jhk-${new Date ().getTime ()}.${type}` ;
            link.click () ;
            window.setTimeout ( () => {
                // 需要释放,防止内存泄漏
                URL.revokeObjectURL ( csvUrl ) ;
                document.body.removeChild ( link ) ;
            }, 60 * 1000 ) ;
            const currentItem = {
                imgId: ops.item.id,
                progress: 100
            } ;
            ops.store.commit ( `Search/updateDownLoadingList`, currentItem ) ;
        }
        return [null as Error, { code: 200 }] ;
    }
    return [null as Error, { code: 500 }] ;
} ;

// 获取下载状态 http://doc.bigbigwork.com/docs/yyx/7100
export const getDownloadState = async ( ) => {
    try {
        const token = getToken () ;
        const res = await axios ( {
            method: `GET`,
            url: ServerPService + `/img/addTodayDownLoad`,
            params: {
                token
            }
        } ) ;
        if ( res.status === 200 ) {
            return [null, res.data] ;
        }
        return [res, null] ;
    } catch ( e ) {
        return [e, null] ;
    }
} ;
