<template>
    <el-dialog :visible="isShow"
               custom-class="cDialogPaySuccessImg"
               :append-to-body="true"
               :close-on-click-modal="false"
               :fullscreen="true"
               :modal-append-to-body="true"
               @close="onClose"
               :show-close="true"
               top="0px">
        <div :class="[$style[`pay-success`],'font-medium']">
          <img :class="$style[`pay-success-img`]" :src="`${process.env.CDN_DZ_PATH}/3w/dz/tc_ffcg.png`" alt="支付成功"/>
        </div>
    </el-dialog>
</template>
<script lang=ts>
    import Vue from "vue";
    const Dialog = require("element-ui/lib/dialog").default;

    Vue.use(Dialog)

    export default {
        name: "cDialogPaySuccess",
        data: function () {
            return {
                isShow: true
            }
        },
        methods: {
            onClose() {
              // 压缩图不刷新页面
              if (!window.location.href.match('yasuotu')){
                window.location.reload();
              }else{
                document.body.style.paddingRight = ''
                window.document.body.style.overflowY = '';
                 this.$store.dispatch ( `UserInfo/FETCH_USER_INFO`, true) ;
              }
            },
          closed(){
            this.isShow = false
          }
        },
        props: {
            testProp: {
                type: String,
                required: false
            }
        },
        mounted() {
        }
    }
</script>
<style lang=scss module src="./DialogPaySuccess.scss"></style>
